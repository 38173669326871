import React from 'react';
import './project.css'; // Import your project-specific CSS file

const Project = ({ title, description, imageUrl, videoUrl, githubUrl, skills}) => {
  return (
    <div className='project-container'>
      <div className='project-content'>
        <h2>{title}</h2>
        <p>{description}</p>
        <div className='project-media'>
          {imageUrl && <img src={imageUrl} alt={`${title} Thumbnail`} />}
          {videoUrl && (
            <iframe
              title={`${title} Video`}
              width='560'
              height='315'
              src={videoUrl}
              frameBorder='0'
              allowFullScreen
            ></iframe>
          )}
          <p>Skills: {skills}</p>
          <a href={githubUrl} target='_blank' rel="noreferrer"><h3>Github Repository</h3></a>
        </div>
      </div>
    </div>
  );
};

export default Project;
