import './contact.css';
import React, { useState } from 'react';
import {useRef} from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {
    

      const form = useRef();

      const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_jv5h5hc', 'template_e64d5ml', form.current, 'Prp6UDo4TpRWerEXc')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });

          e.target.reset();
      };
      const onClear = () => {
        const form = document.getElementById('myForm');

        Array.from(form.elements).forEach((element) => {
          const elementType = element.type.toLowerCase();

          switch (elementType) {
            case 'text':
            case 'email':
              element.value = '';
              break;
          }
        })
      }
    return (
        <div>
            <div class="background">
  <div class="container">
    <div class="screen">
      <div class="screen-header">
        <div class="screen-header-left">
          <div class="screen-header-button close"></div>
          <div class="screen-header-button maximize"></div>
          <div class="screen-header-button minimize"></div>
        </div>
        <div class="screen-header-right">
          <div class="screen-header-ellipsis"></div>
          <div class="screen-header-ellipsis"></div>
          <div class="screen-header-ellipsis"></div>
        </div>
      </div>
      <div class="screen-body">
        <div class="screen-body-item left">
          <div class="app-title">
            <span>CONTACT</span>
            <span>ME</span>
          </div>
          <div class="app-contact">CONTACT INFO : +1 (502) 727 2698 <br/>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          samdeluciagreen@gmail.com</div>
        </div>
        <div class="screen-body-item">
          <form ref={form} onSubmit={sendEmail} id='myForm'>
          <div class="app-form">
            <div class="app-form-group">
              <input class="app-form-control" placeholder="NAME" 
                type='text' name='user_name'/>
            </div>
            <div class="app-form-group">
              <input class="app-form-control" placeholder="EMAIL"
                type='email' name='user_email'/>
            </div>
            <div class="app-form-group">
              <input class="app-form-control" placeholder="CONTACT NO"
                type='text' name='contact'/>
            </div>
            <div class="app-form-group message">
              <input class="app-form-control" placeholder="MESSAGE"
                name='message' type='text'/>
            </div>
            <div class="app-form-group buttons">
              <button class="app-form-button" type='button' onClick={onClear}>CANCEL</button>
              <button class="app-form-button" type='submit' value="Send">SEND</button>
            </div>
          </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

        </div>
    )
}

export default Contact;