import './home.css'
import Project from './project';

const NewProjects = () => {
  return (
    <div>
      <div className='projects-container'>
        <h2 className='projects-title'>Projects</h2>
        <Project
          title='Voice Synthesis Web App with AWS Polly'
          description='Developed a cutting-edge Text-to-Speech web application using Angular, AWS Polly, Lambda, and S3. Leveraged AWS Polly AI-driven voice synthesis to transform user-provided text into natural-sounding spoken words. Implemented a serverless architecture with AWS Lambda for efficient and scalable processing, while storing and retrieving audio files securely in AWS S3. The application offers an intuitive user interface, allowing users to input text, select preferred voices, and instantly generate spoken content. This project showcases proficiency in AWS cloud services, serverless computing, and modern web development.'
          githubUrl={'https://github.com/SamDG7/TextToSpeechApp'}
          skills='AWS CLI (Polly, Lambda, S3), Python, Angular, HTML, CSS, JavaScript'
        />

        <Project
          title='BookXchange Mobile App'
          description='An IOS application that offers users a tinder-like interface for the exchange and discussion of books in person. Includes specialized algorithm for user-specifc book-swiping order, 
          as well as end-to-end encryption for messaging matches. Includes custom animations and personalized 
          emojis with an intuitive user-interface for accessing ones profile, library, and messages.'
          imageUrl=''
          githubUrl={'https://github.com/SamDG7/BookXchange'}
          skills='Agile Development, Flutter, Dart, Python, Flask, MongoDB, Firebase Authentication, Cloud Firestore, Postman'
        />

        <Project
          title='AI Sudoku Solver Web App'
          description='Created a sudoku solver desktop application for BoilerMake 2023 Purdue Hack-A-Thon. Takes a photo of an unfinished sudoku problem and uses modified versions of pythons opencv and the pytesseract AI 
          to interpret the image into a 2D array. Then, backtracking and crosshatching algorithm techniques are used to solve the puzzle. 
          The front-end of the app was constructued using react.js'
          githubUrl={'https://github.com/lzugaz/BoilerMake2023'}
          skills='React.js, Python, OpenCV, Pytesseract, Data Structures and Algorithms, C++'
        />
        <Project
          title='Personal Shell'
          description='Created a personal shell program from an enhanced command line experience. 
          Lex and Yacc were used to parse terminal input and C/C++ to process requests. The shell handles all typical bash terminal commands and operations, 
          as well as a few personal additions.'
          githubUrl={'https://github.com/SamDG7/PersonalShell'}
          skills='C/C++, Lex, Yacc, Data Structures and Algorithms, Systems Programming'
        />
        <Project
          title='Personal Website'
          description='This project is the very website you are on. This website was contructed in HTML, CSS, and JavaScript, utilizing react.js and Node.js'
          githubUrl={'https://github.com/SamDG7/final-personal-website'}
          skills='HTML, CSS, JavaScript, React.js, Node.js'
        />

        {/* Add more Project components for each project */}
      </div>
    </div>
  );
};

export default NewProjects;
