
import Nav from './components/nav';
import Contact from './components/contact';
import Home from './components/home';
import NewProjects from './components/new_projects';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
    <div className="App outline">
      <Nav />
      {/* <Home></Home>
      <Experience></Experience>
      <Projects></Projects>
      <Contact></Contact> */}

      <div>
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='projects' element={<NewProjects />}></Route>
          <Route path='/contact' element={<Contact />}></Route>
        </Routes>
      </div>
    </div>
    </Router>
  );
}

export default App;
