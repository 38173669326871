import './home.css';
import Contact from './contact';
import NewProjects from './new_projects';

const Home = () => {
  return (
    <div>
      <div className='back'>
        <div className='center'>
          <h1>Sam DeLucia-Green</h1>
        </div>
        <div className='lower'>
          <h1>Student and Aspiring Software Engineer</h1>
        </div>
      </div>
      {/* <div className='projects-container'>
        <h2 className='projects-title'>Projects</h2>
        <Project
          title='Project 1'
          description='Description for Project 1.'
          imageUrl='https://example.com/project1.jpg'
          videoUrl='https://www.youtube.com/embed/example'
          githubUrl={'https://github.com/example'}
        />

        <Project
          title='Project 2'
          description='Description for Project 2.'
          imageUrl='https://example.com/project2.jpg'
          videoUrl='https://www.youtube.com/embed/example'
          githubUrl={'https://github.com/example'}
        /> */}

        {/* Add more Project components for each project */}
      {/* </div> */}
      <div>
        <NewProjects />
      </div>
      <div>
        <Contact />
      </div>
    </div>
  );
};

export default Home;
