import linkedinLogo from '../linkedin-icon.png';
import gitLogo from '../git.png';
import resume from '../samresume.pdf';

import './nav.css';

const Nav = () => {
    return (  
        <div>
           <a href="https://www.linkedin.com/in/samdelucia/" class="linkedIn" target="_blank"rel="noreferrer"><img src={linkedinLogo} alt=""></img></a>
           <a href="https://www.github.com/SamDG7" class="github" target="_blank"rel="noreferrer"><img src={gitLogo} alt=""></img></a>  
           <input class="menu-icon" type="checkbox" id="menu-icon" name="menu-icon"/>
           <label for="menu-icon"></label>
           
           <nav class="nav"> 		
  		    <ul class="pt-5">
  			    <li><a href="/">Home</a></li>
  			    <li><a href={resume} target='_blank' rel="noreferrer">Experience</a></li>
  			    <li><a href="/projects">Projects</a></li>
  			    <li><a href="/contact">Contact</a></li>
  		    </ul>
  	        </nav>
        
        {/* <div class="section-center">
            <h1 class="mb-0">Sam DeLucia-Green</h1>
        </div> */}
        </div>
    );
  }

  export default Nav;